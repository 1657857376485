import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import TextLeft from "../components/TextLeft"

const Lumber = ( {data} ) => {

  const lumberImage = getImage(data.lumberImage)
  const cedarDecking = getImage(data.cedarDecking)
  const cedarPanelOne = getImage(data.cedarPanelOne)
  const cedarPanelTwo = getImage(data.cedarPanelTwo)

  return (
    <Layout formTitle="Have a question about our lumber selection? Let us know.">
      <Seo 
        title="Lumber"
        description="We manufacture quality dimensional milled Northern White Cedar lumber including decking, panelling, log siding, and custom milled timber." 
      />
      <div className="relative bg-color">
        <div className="container mx-auto pt-32 md:pt-48 pb-32 md:pb-48">
          <TextLeft
            heading={<h1>Lumber &amp; Timber</h1>}
            subHeading={<p>We sell top quality cedar, pine, spruce, and fir lumber. Most of the lumber we make is bundled with our log home packages, but there is such a demand for our quality Northeast Kingdom lumber that we now sell it directly from our mill in Hardwick, VT.</p>}
            image={lumberImage}
            altText="a pile of logs in Vermont"
            addedClasses="md:items-center"
          />
        </div>
      </div>
      <div className="container mx-auto mt-32 md:mt-48">
        <div className="grid grid-cols-1 md:grid-cols-7 gap-12 md:gap-24">
          <div className="col-span-2">
            <GatsbyImage 
              image={cedarDecking}
              alt="Northern White Cedar Decking"
            />
          </div>
          <div className="col-span-5">
            <h2>Northern White Cedar Decking</h2>
            <p>Our Eased-Edge Cedar Decking is smooth, clean, easy to work with, and full of natural character. If you are building a deck, porch, or dock there is nothing else that is as affordable and long-lasting as Northern White Cedar decking from Authentic Log Homes.</p>
            <p>Northern White Cedar is the ideal natural material for rain, snow, and weather exposed projects. There is no other native wood species that is as ideally suited for North American climates and our seasonal variations. Authentic Log Homes Northern White Cedar Eased-Edge Decking is naturally rot, mildew, and weather resistant. What you get is an all natural product that will hold up against the elements without environmentally hazardous chemical treatments and preservatives.</p>
            <p>Don't forget we can also quote other dimensional deck/porch building materials: posts, railings, fascia, joists, and framing. If you have priced decking from somewhere else we think you will be pleasantly surprised by our price!</p>
            <p><strong>Note: Please be a good steward of our environment! Do not put chemically treated wood products in waterways, or where water runoff will enter wetlands. (For these applications our lumber is ideally suited!)</strong></p>
          </div>
        </div>
        <hr className="my-24 md:my-36" />
        <h2 className="mb-16">Northern White Cedar Panelling</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24">
          <div>
            <GatsbyImage 
              image={cedarPanelOne}
              alt="Northern White Cedar Panelling"
            />
            <h3 className="mt-6">Tongued &amp; V-Groove Boards</h3>
            <p>Northern White Cedar Tongue &amp; V-Groove (TVG) panelling is beautiful, natural, and easy to install and maintain. It is the perfect choice for finishing interior walls, ceilings, and other building projects.</p>
            <p className="mb-2">Sizes Available:</p>
            <ul>
              <li className="mb-2">1" x 4" (8' - 12' lengths)</li>
              <li className="mb-2">1" x 5" (8' - 12' lengths)</li>
              <li className="mb-2">1" x 6" (8' - 12' lengths)</li>
            </ul>
          </div>
          <div>
            <GatsbyImage 
              image={cedarPanelTwo}
              alt="Northern White Cedar Panelling"
            />
            <h3 className="mt-6">Shiplap Boards</h3>
            <p>Northern White Cedar Shiplap panelling can create the same kind of look as the Tongue &amp; V-Groove, but offers easier assembly and the choice of reversing the boards for a flush finish without the "V".</p>
            <p className="mb-2">Sizes Available:</p>
            <ul>
              <li className="mb-2">1" x 4" (8' - 12' lengths)</li>
              <li className="mb-2">1" x 5" (8' - 12' lengths)</li>
              <li className="mb-2">1" x 6" (8' - 12' lengths)</li>
            </ul>
          </div>
        </div>
        <hr className="my-24 md:my-36" />
        <h2>Northern White Cedar Log Siding</h2>
        <p>Our log siding is a great alternative to full log construction. You get the same look in a more flexible application. Our log siding can be used on existing conventional framed buildings, garages, and sheds to match the look of a log home. We even make log siding with false-corners that replicate the intersecting corner style of a log homes.</p>
        <p className="mb-2">Sizes Available:</p>
        <ul>
          <li className="mb-2">6" (8' to 12' lengths)</li>
        </ul>
        <hr className="my-24 md:my-36" />
        <h2>Custom Milled Timber</h2>
        <p>We can custom mill posts, beams, rafters, and other dimensional cedar, pine, spruce, and fir lumber at our mill in Hardwick, VT</p>
        <p className="mb-2">Sizes Available:</p>
        <ul>
          <li className="mb-2">Anything up to 8" x 15" x 25'</li>
        </ul>
        <p className="mb-2">Surfaces Available:</p>
        <ul>
          <li className="mb-2">Any combination of rough cut, planed, and live faces. (s1s, s2s, s3s, s4s)</li>
        </ul>
        <hr className="my-24 md:my-36" />
      </div>
    </Layout>
  );
}
 
export default Lumber;

export const pageQuery = graphql`
  query {
    lumberImage: file(relativePath: { eq: "ALHOV_lumber.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          )
      }
    },
    cedarDecking: file(relativePath: { eq: "ALHOV_cedar_decking.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          )
      }
    },
    cedarPanelOne: file(relativePath: { eq: "ALHOV_Northern_White_Cedar_Panelling_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          )
      }
    },
    cedarPanelTwo: file(relativePath: { eq: "ALHOV_Northern_White_Cedar_Panelling_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          )
      }
    },
  }
`